<template>
  <div>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="userCreateVal"
      >
        <b-row>
          <b-col
            v-for="field in ['firstname',
                             'lastname',
                             'email']"
            :key="field"
            md="6"
          >
            <component
              :is="fields[field].type"
              v-model="userForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col
            v-for="field in [
              'phone',]"
            :key="field"
            md="6"
            class="d-flex"
          >
            <div class="w-75">
              <component
                :is="fields[field].type"
                v-model="userForm[field]"
                v-bind="getProps(field)"
                :is-editable="isEdit"
                :default-country-code="getDefaultCountryCode()"
                @input-obj="setObjectOfPhoneMusk($event, userForm,'phone_no_object')"
              />
            </div>
            <div class="w-25">
              <label class="pt-0">{{ $t('Ext number') }}</label>
              <b-form-input
                v-model="userForm[`${field}_ext_val`]"
                class="mb-1"
                style="height: 38px"
                placeholder="ext number"
                :disabled="isEdit"
              />
            </div>
          </b-col>
          <b-col
            v-for="field in [
              'username',
              'warehouse_id',
              'title',
              'role_id']"
            :key="field"
            md="6"
          >
            <component
              :is="fields[field].type"
              v-model="userForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>

          <b-col
            v-for="field in ['password']"
            :key="field"
            md="6"
          >
            <div
              class="d-flex align-items-center"
              style="width: 100%;"
            >
              <component
                :is="fields[field].type"
                v-model="userForm[field]"
                v-bind="getProps(field)"
                :is-editable="isEdit"
                :password-generate="isCreateForm"
                class="flex-grow-1"
                @suggestion="suggestion()"
              />
            </div>
          </b-col>

          <b-col
            v-for="field in ['setting_notifications_id','password_confirmation']"
            :key="field"
            md="6"
          >
            <component
              :is="fields[field].type"
              v-model="userForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>

          <b-col
            v-for="field in ['status']"
            :key="field"
            md="6"
            class="is_active_items"
          ><span class="inputLabel">{{ $t('Status') }}</span>
            <component
              :is="fields[field].type"
              v-model="userForm[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>

        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="isFormChanged"
                class="cancelBtn font-weight-bolder"
                variant="outline-primary"
                @click="this.loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="clear"
              >
                {{ $t('Back to List') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>
import { required, email } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import userMenuItems from '@/views/components/sub-menu/settings-sub-menu/users'
import { ValidationObserver } from 'vee-validate'
import {
  BForm, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import jsonToFormData from '@/libs/jsonToFormData'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import config from '../userConfig'

export default {
  name: 'UserForm',
  components: {
    BFormInput,
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  props: {
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      email,
      error: {},
      onSubmit: false,
    }
  },
  computed: {
    user() {
      return this.$store.state[this.MODULE_NAME].user
    },
    userForm() {
      return this.$store.state[this.MODULE_NAME].userForm
    },
    roleList() {
      return this.$store.state.listModule.roleList
    },
    warehouseList() {
      return this.$store.state.listModule.warehouseList
    },
    userFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].settingUserForm
    },
    isFormChanged() {
      return JSON.stringify(this.userForm) !== this.userFormClone
    },
  },
  mounted() {
    if (!this.isCreateForm) {
      const data = {
        ...this.userForm,
        ...{
          phone_ext_val: this.user?.phone_no_object?.phone_no_ext_val ?? null,
        },
      }
      this.$store.commit(`${this.MODULE_NAME}/SET_USER_FORM`, data)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_USER_FORM_CLONE`, data)
      this.getDefaultCountryCode()
    }
    // setTimeout(() => {
    //   this.$refs.contactForm.reset()
    // }, 0)
  },
  methods: {
    getDefaultCountryCode(countryCode) {
      if (!this.isCreateForm) {
        return countryCode ? 'US' : this.user?.phone_no_object?.countryCode ?? 'US'
      }
      return countryCode ?? 'US'
    },
    suggestion() {
      const randomString = this.generatorPassword(16)
      return this.$store.state[this.MODULE_NAME].userForm = { ...this.$store.state[this.MODULE_NAME].userForm, ...{ password: randomString, password_confirmation: randomString } }
    },
    generatorPassword(length) {
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      let randomString = ''

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length)
        randomString += charset[randomIndex]
      }
      return randomString
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    loader() {
      this.$refs.userCreateVal.reset()
      const smth = JSON.parse(this.userFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_USER_FORM`, smth)
    },
    submit() {
      this.error = {}
      // this.onSubmit = true
      this.$refs.userCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.userForm
            const userForm = this.mappingFields(Object.keys(this.fields), data, {
              id: data.id,
              role_id: data.role_id?.id,
              setting_notifications_id: data.setting_notifications_id?.id,
              warehouse_id: data.warehouse_id?.id,
              phone_no_object: JSON.stringify({ ...data.phone_no_object, phone_no_ext_val: data.phone_ext_val }),
            })

            // eslint-disable-next-line new-cap
            const request = new jsonToFormData(userForm)
            this.sendNotification(this, request, `${this.MODULE_NAME}/${data.id ? 'updateUser' : 'createUser'}`)
              .then(response => {
                if (this.isCreateForm) {
                  this.$store.commit(`${this.MODULE_NAME}/SET_USER_FORM`, {})
                  this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_USER_FORM_CLONE`, {})
                  this.$router.push({ name: 'user-list' })
                }
                const { data } = response.data

                const resp = this.mappingFields(Object.keys(this.fields), data, {
                  warehouse_id: data.warehouse,
                  role_id: data.role,
                  phone_no_object: data.phone_no_object,
                })
                this.resetDate(resp)
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.userCreateVal)
          }
        })
    },
    resetDate(data) {
      const mutationDate = {
        ...data,
        ...{ phone_ext_val: data?.phone_no_object?.phone_no_ext_val },
      }

      this.getDefaultCountryCode(mutationDate?.phone_no_object?.countryCode ?? 'US')
      this.$store.commit(`${this.MODULE_NAME}/SET_USER_FORM`, mutationDate)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_USER_FORM_CLONE`, mutationDate)
    },
    clear() {
      this.$refs.userCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/GET`, {})
      this.$router.push({ name: 'user-list' })
    },
    getProps(fieldName) {
      const props = {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        value: this.userForm.firstname,
        name: fieldName,
      }
      if (fieldName === 'phone') {
        const JSONPhoneField = this.fields[fieldName].JSONName
        this.fields[fieldName].options = {
          'default-country-code': this.userForm[JSONPhoneField]?.countryCode,
        }
      }
      if (this.isUpdateForm) {
        if (fieldName === 'password_confirmation') {
          props.field.rules = 'confirmed:password'
        } else if (fieldName === 'password') {
          props.field.rules = null
        }
      }

      return props
    },
  },
  setup() {
    const MODULE_NAME = 'users'
    const MODULE_NAME_CLONE = 'cloneData'

    const { menuItems } = userMenuItems('users')
    const { fields, setObjectOfPhoneMusk } = config()

    onUnmounted(() => {
      store.commit(`${MODULE_NAME}/SET_USER_FORM`, {})
    })

    return {
      setObjectOfPhoneMusk,
      fields,
      menuItems,
      MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
<style scoped>

</style>
